<template>
    <s-menus
        class="el-menu-vertical-demo" 
        :menu-items="menus"
        :collapse="isCollapse"
        :router="true"
        :default-active="defaultActive"
        ></s-menus>
</template>


<script>
    import { ref, computed, inject, watch } from 'vue'
    import { useStore } from 'vuex';
    import { useRoute } from "vue-router";
    import SMenus from './sMenus'

    export default {
        components: {SMenus},
        setup () {
            const route = useRoute()
            let count = ref(9)
            let defaultActive = ref(route.path)
            // console.log(defaultActive)
            let isCollapse = inject('isCollapse')
            let store = useStore();
            let menus = computed(() => store.getters.GET_MENUS)
            // 路由监听
            // onBeforeRouteUpdate((to) => {
            // });
            watch(() => route.path, () => {
                defaultActive.value = route.path
                // console.log('监听到变化')
            })
            // let router = watch('router')
            return {
                menus,
                count,
                isCollapse,
                defaultActive
            }
        }
    }
</script>

<style lang="scss" scoped>
.el-menu{
    border-right: none;
}
</style>

<style lang="scss" >
.el-menu{
    background-color: #304156;
}
.el-menu-item:focus, .el-menu-item:hover{
    background-color: #202D3D;
}
</style>