<template>
    <el-container class="el-app-container">
        <el-aside :width="isCollapse ? '64px' : '240px'">
            <side-menus text-color="#ffffff" active-text-color="#1791FF" background-color="#304156"></side-menus>
        </el-aside>
        <el-container style="height: 100%">
            <el-header class="layout-header">
                <layout-header></layout-header>    
            </el-header>
            <el-main style="background-color: #FAFAFA;">
                <layout-body/>
                <el-backtop target=".el-main"></el-backtop>
            </el-main>
        </el-container>
    </el-container>
</template>


<script>
import { ref, provide } from 'vue'
import LayoutHeader from './layoutHeader'
import LayoutBody from './layoutBody.vue'
import SideMenus from '../menus/index.vue'

export default {
    components: {LayoutHeader, LayoutBody, SideMenus},
    setup() {
        let isCollapse = ref(false)
        provide('isCollapse', isCollapse)

        
        return {
            isCollapse
        }
    }
}
</script>

<style lang="scss" scoped>
.el-app-container{
    height: 100%;

    .layout-header{
        border-bottom: 1px solid #eeeeee;
        background-color: #ffffff;
    }

    .el-aside{
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 200ms;
        background-color: #304156;
        .logo{
            height: 56px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 4px;
            color: #ffffff;
            text-shadow: 0 10px 20px rgab(0,0,0,0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2C2F3A;
        }
        /deep/ .el-menu-item:focus, /deep/ .el-menu-item:hover{
            background-color: #304156;
        }
        /deep/ .el-menu-item.is-active{
            background-color: #202D3D;
        }
        /deep/ .el-menu-item, /deep/.el-submenu__title{
            background-color: #304156;
        }

    }
}

</style>